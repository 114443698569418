// ** React Imports
import { useContext, useEffect } from "react";

// ** Redux & Store & Actions
import { connect, useSelector } from "react-redux";
import { setFingerprint } from "store/modules/global";

// ** Third Party Components
import cookie from "js-cookie";

import loadable from "@loadable/component";

// ** Components
const MainContentAuth = loadable(() => import("./MainContentAuth"));

// ** Custom Hooks
import { useFingerprint } from "hooks/useFingerprint";

// ** Contexts
import authContext from "contexts/authContext";

// ** Config
import configGlobal from "configuration";

// ** Styled Components
import { StyledMainContent } from "./styles";

// #####################################################

const MainContent = ({ children, setFingerprint, locationPathname }) => {
	const isUserpanel = locationPathname.startsWith("/userpanel");

	const actualFingerprint = useSelector(
		({ global: { fingerprint } }) => fingerprint
	);

	const isAuth = useContext(authContext);

	const { getFingerprint } = useFingerprint();

	const cookieFingerprint = cookie.get(configGlobal.fingerprint.cookieName);

	// ** Fingerprint
	useEffect(() => {
		if (!actualFingerprint && isAuth) {
			setFingerprint(cookieFingerprint);
		}
		if (!cookieFingerprint && isAuth) {
			getFingerprint();
		}
	}, [cookieFingerprint, isAuth]);

	// #####################################################

	return (
		<StyledMainContent isUserpanel={isUserpanel}>
			{isAuth && <MainContentAuth />}
			{children}
		</StyledMainContent>
	);
};

// #####################################################

export default connect(null, {
	setFingerprint,
})(MainContent);

// #####################################################
